import React, { useState, useEffect, useRef, memo } from "react";
import InsertChartOutlinedIcon from "@mui/icons-material/InsertChartOutlined";
import Tick from "@pqina/flip";
import "@pqina/flip/dist/flip.min.css";
import firebase from "firebase/app";
import "firebase/database";

const firebaseConfig2 = {
  apiKey: "AIzaSyBkMIvidIGAsmwbkX16_m45aNdTWdfe7t8",
  authDomain: "zoocorder.firebaseapp.com",
  databaseURL: "https://zoocorder.firebaseio.com",
  projectId: "zoocorder",
  storageBucket: "zoocorder.appspot.com",
  messagingSenderId: "282767790318",
  appId: "1:282767790318:web:c955404a751656a59a63ff",
  measurementId: "G-C0DF9JP1QV",
};

const app2 = firebase.initializeApp(firebaseConfig2);

const zooDB = firebase.database(app2);

const WebinarCounter = ({ isMobile }) => {
  const [count, setCount] = useState("");
  const divRef = useRef();

  useEffect(() => {
    const currDiv = divRef.current;
    const tickValue = count;
    Tick.DOM.create(currDiv, {
      value: count,
    });
    return () => Tick.DOM.destroy(tickValue);
  });

  useEffect(() => {
    const counterRef = zooDB.ref("counters/webinars");
    const listener = counterRef.on("value", (snapshot) => {
      setCount(snapshot.val());
    });

    return () => {
      counterRef.off("value", listener);
    };
  }, []);

  return (
    <>
      {isMobile ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            cursor: "pointer",
          }}
          onClick={() => {
            // window.open(
            //   "https://lookerstudio.google.com/u/0/reporting/89d08881-73f1-4c1c-bf36-810650b83ceb/page/p_advoyk0x4c"
            // );
          }}
        >
          <div
            ref={divRef}
            className="tick"
            style={{
              cursor: "pointer",
              flexWrap: "wrap",
              marginTop: "2px",
              marginLeft: "5px",
              justifyContent: "flex-start",
              alignItems: "flex-end",
            }}
            data-value={count}
            // onClick={() => { window.open("https://lookerstudio.google.com/reporting/89d08881-73f1-4c1c-bf36-810650b83ceb/page/p_mxh10dbt2c") }}
          >
            <div
              data-repeat="true"
              style={{
                display: "inline-flex",
                alignItems: "flex-end",
                wrap: "nowrap",
              }}
            >
              <span data-view="flip" className="tick-flip">
                Tick
              </span>
            </div>

            <span className="tick-label">Webinars</span>
            {/* <InsertChartOutlinedIcon className="tick-icon" sx={{marginBottom: 0, paddingBottom: 0, alignSelf: "end", marginLeft: "5px", }}/> */}
          </div>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            cursor: "pointer",
          }}
          onClick={() => {
            // window.open(
            //   "https://lookerstudio.google.com/u/0/reporting/89d08881-73f1-4c1c-bf36-810650b83ceb/page/p_advoyk0x4c"
            // );
          }}
        >
          <span
            className="tick-label"
            style={{
              borderBottom: "1px solid white",
              fontSize: "20px",
              letterSpacing: "4px",
            }}
          >
            Webinars
          </span>
          <div
            ref={divRef}
            className="tick"
            style={{
              cursor: "pointer",
              flexWrap: isMobile ? "wrap" : "nowrap",
              marginTop: "2px",
              marginLeft: "10px",
              justifyContent: "space-between",
            }}
            data-value={count}
            // onClick={() => { window.open("https://lookerstudio.google.com/reporting/89d08881-73f1-4c1c-bf36-810650b83ceb/page/p_mxh10dbt2c") }}
          >
            <div
              data-repeat="true"
              style={{
                display: "inline-flex",
                alignItems: "flex-end",
                wrap: "nowrap",
              }}
            >
              <span data-view="flip" className="tick-flip">
                Tick
              </span>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default memo(WebinarCounter);
